@import url("https://cdn.bulletscout.com/css/fonts.css");

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  min-height: 100%;
  height: 100%;
  background-color: #fbfbfb;
  color: #45474b;
}

#root {
  height: 100%;
}

.scroll-off::-webkit-scrollbar {
  width: 0px;
}
